* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Copperplate';
  src: url('../fonts/CopperPlate/copperplate.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-SemiBold.ttf');
  src: local('Raleway SemiBold'), local('Raleway SemiBold'),
    url('../fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Medium.ttf');
  src: local('Raleway Medium'), local('Raleway Medium'),
    url('../fonts/Raleway/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/Raleway/Raleway-Regular.ttf');
  src: local('Raleway Regular'), local('Raleway Regular'),
    url('../fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  font-variant-numeric: lining-nums proportional-nums !important;
}

html,
body {
  font-family: 'Copperplate', sans-serif;
  /* color: rgba(450, 450, 450); */
  font-size: 14px;
  position: relative;
  min-height: 100vh;
  background: #ffffff66;
}

body {
  overflow-x: hidden !important;
}

@media (min-width: 576px) {
  html,
  body {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
  }
}

@media (max-width: 320px) {
  html {
    width: 320px;
  }
}

@media (min-width: 992px) {
  main {
    min-height: 50vh;
  }
}

@media (max-width: 1024px) {
  main {
    padding-top: 120px !important;
  }
}

input,
textarea {
  font-family: inherit;
}

ol,
ul {
  list-style: none;
}

ul.list-style li {
  /* display: flex;
  align-items: center; */
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  margin-left: 30px;
}

ul.list-style li::before {
  content: '';
  position: absolute;
  display: inline-block;
  height: 25px;
  width: 30px;
  background-image: url('./icon-list.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  left: -30px;
  top: 0;
}

a {
  text-decoration: none;
  color: #000;
  line-height: normal;
  transition: all 0.2s ease-in;
}

a:hover div:last-child img {
  transform: scale(1.1);
  transition: transform 0.3s;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.menu-item a {
  padding: 20px 15px;
  color: #000;
}

.menu-item a.active {
  color: rgba(103, 40, 51);
}

.btn {
  outline: none;
  border: none;
  font-weight: 400;
  padding: 12px 24px;
  font-family: 'Copperplate';
  background: #be264c;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
}

.btn-small {
  font-size: 20px;
  line-height: 20px;
}

.btn-big {
  padding: 16px 24px;
  font-size: 20px;
  line-height: 20px;
}

.btn-custom {
  border-radius: 0;
  background: none;
  color: #672833;
  position: relative;
  z-index: 2;
}

.btn-custom span {
  padding-left: 50px;
  /* иправление бага с дублированием текста */
  /* z-index: 2; */
  position: relative;
}

.btn-custom span:before {
  content: '';
  position: absolute;
  top: calc(50% - 1.5px);
  left: 0;
  background: #652734;
  height: 3px;
  width: 45px;
}

.btn-custom::before {
  content: '';
  height: 40px;
  width: 45px;
  position: absolute;
  aspect-ratio: 1/1;
  top: 0;
  left: 0;
  background: #652734;
  clip-path: polygon(
    10px 0%,
    calc(100% - 10px) 0%,
    100% 50%,
    calc(100% - 10px) 100%,
    10px 100%,
    0% 50%
  );
  z-index: 1;
  transition: all 0.5s ease-in;
}

.btn-custom:hover::before {
  width: calc(100% + 45px);
}

.btn-custom::after {
  height: 40px;
  position: absolute;
  color: #fff;
  left: 0;
  top: 0;
  width: calc(100% + 45px);
  display: flex;
  align-items: center;
  content: attr(title);
  z-index: 2;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease-in;
}

.btn-custom:hover::after {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

/* .section {
  min-height: 100vh;
} */

.container {
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    /* max-width: calc(100% - 32px); */
    max-width: 720px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 944px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1360px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1441px) {
  .container {
    max-width: 1320px;
  }
}

h1 {
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
}

h2 {
  font-weight: 400;
  font-size: 72px;
  line-height: 80px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
}

h3 {
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
}

h4 {
  font-size: 32px;
  line-height: 36px;
}

h5 {
  font-size: 18px;
}

@media (max-width: 576px) {
}

/* section {
  padding-bottom: 50px;
  padding: 80px 0px;
  min-height: 100vh;
} */

.section {
  opacity: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(0%);
}

/* .section.element-show {
  opacity: 1;
  transition: all 1s;
  transform: translateY(0%);
} */

.collapse {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.collapse.show {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}

.height100vh200 {
  min-height: calc(100vh - 200px);
}

.message-form {
  position: absolute;
  left: calc(50% - 50px);
  color: #672833;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in;
}

.d-none {
  display: none !important;
}

/* .background {
  width: 100%;
  height: 100%;
  background-image: url('../../pages/Home/components/HeaderBanner/images/Section_Hero.svg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
} */

.swiper-container {
  overflow: visible !important;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
}

.custom-swiper-button-prev {
  background-image: url('./arrowLeft.svg');
}
.custom-swiper-button-next {
  background-image: url('./arrowRight.svg');
}

.custom-swiper-button-prev:hover,
.custom-swiper-button-next:hover {
  background-color: rgb(145, 0, 49);
}

.custom-swiper-button-prev,
.custom-swiper-button-next {
  background-color: #a40c3b;
  /* padding: 24px !important; */
  transition: 0.2s;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 12px;
  cursor: pointer;
}

@media (max-width: 1024px) {
  /* .btnA, */
  .btnA button {
    width: 100% !important;
  }
}

@media (min-width: 320px) {
  .custom-pagination {
    display: none;
  }
}

@media (min-width: 768px) {
  .custom-pagination {
    display: flex;
    gap: 16px;
  }
}
